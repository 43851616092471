document.addEventListener('DOMContentLoaded', () => {
  let $hamburger = document.querySelector(".hamburger-menu.open-menu"),
      $nav = document.querySelector(".global.header nav")
  if($hamburger)
    $hamburger.addEventListener("click", (e) => toggleBurgerMenu(e))

  const toggleBurgerMenu = (e) => {
    e.preventDefault();
    toggleClass($hamburger, "open")
    toggleClass($nav, "open")
  }

  let $navContact     = $nav.querySelector("a.contact"),
      $contactEl      = document.querySelector("section.contact"),
      $navAbout       = $nav.querySelector("a.about"),
      $aboutEl        = document.querySelector("section.about"),
      $navSpecialist  = $nav.querySelector("a.specialists"),
      $specialistEl   = document.querySelector("section.specialists"),
      $navDevelopment = $nav.querySelector("a.development"),
      $developmentEl  = document.querySelector("section.development")

  $navContact.addEventListener("click",     (e) => scrollToEl(e, $contactEl))
  $navAbout.addEventListener("click",       (e) => scrollToEl(e, $aboutEl))
  $navSpecialist.addEventListener("click",  (e) => scrollToEl(e, $specialistEl))
  $navDevelopment.addEventListener("click", (e) => scrollToEl(e, $developmentEl))

})


const scrollToEl = (e, el) => {
  e.preventDefault()
  if(el)
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
}

const toggleClass = (el, className) => {

  if (el.classList) {
    el.classList.toggle(className);
  } else {
    var classes = el.className.split(' ');
    var existingIndex = classes.indexOf(className);

    if (existingIndex >= 0)
      classes.splice(existingIndex, 1);
    else
      classes.push(className);

    el.className = classes.join(' ');
  }
}


// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}